import React from "react"

const HeaderBlob = () => {
  return (
    <svg
      width="100%"
      height="289"
      viewBox="0 0 1436 289"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        d="M0 0C0 0 885.207 1.897 1239.59 2.80219L1436 2.80217V268.634C1063.5 271.325 691.027 285.418 317.779 288.778C173.652 290.076 0 285.26 0 285.26V0Z"
        fill="#FFFBF2"
      />
    </svg>
  )
}

export default HeaderBlob
