import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Container from "../../components/container"
import HeaderBlob from "../../components/header-blob"
import BlogPostCard from "../../components/blog-post-card"
import Seo from "../../components/seo"
import PrismicBlogPostCard from "../../components/prismic-blog-post-card"

export default function Template({ data }) {
  return (
    <Layout>
      <Container noMax>
        <div className="absolute top-5 lg:-top-[-10%] w-full">
          <HeaderBlob />
        </div>

        <div
          className="text-center pt-16 pb-0 max-w-md mx-auto relative"
          style={{ zIndex: 30 }}
        >
          <h3 className="">Tips</h3>
          <hr className="border border-primary" />
          <h1 className="mb-0 mt-2 normal-case text-4xl tracking-normal font-normal text-lightBlack">
            *DRAFTS* Dog Training
          </h1>
        </div>
        <p className="p-2 mt-6 pb-16 max-w-2xl md:p-0 mx-auto text-center relative">
          Learn training tips and about all things dog from a certified dog
          trainer.
        </p>
      </Container>
      <Container>
        <div className="flex flex-wrap justify-center pt-36 mb-36">
          {data.prismicPosts.edges.map(
            ({ node }) =>
              !node.data.is_active && (
                <PrismicBlogPostCard
                  key={node.url}
                  prismicData={{
                    ...node.data,
                    url: `/drafts/${node.url.split("/")[2]}`,
                  }}
                />
              )
          )}
          {data.posts.edges.map(
            ({ node: { frontmatter } }) =>
              !frontmatter.is_active && (
                <BlogPostCard key={frontmatter.slug} data={frontmatter} />
              )
          )}
        </div>
      </Container>
    </Layout>
  )
}

export const Head = () => <Seo title={"Drafts"} />

export const query = graphql`
  {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts//" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featured_image {
              childImageSharp {
                gatsbyImageData
              }
            }
            image_alt_text
            is_active
            category
          }
        }
      }
    }
    prismicPosts: allPrismicBlogPost {
      edges {
        node {
          id
          href
          url
          uid
          _previewable
          data {
            author
            is_active
            title {
              text
            }
            category
            featured_image {
              gatsbyImageData
              alt
            }
          }
        }
      }
    }
  }
`
